<template>
  <v-dialog v-model='dlgOptions' scrollable persistent max-width='400px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          Patient Options
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row align-center>
            <v-col :sm='12' :xs='12'>
              <v-btn
                  text
                  outlined
                  color='primary'
                  style='width: 325px'
                  :disabled='isBusy'
                  :loading='isBusy'
                  @click='bookAppointment'
              >Book Appointment
              </v-btn
              >
            </v-col>
            <v-col :sm='12' :xs='12'>
              <v-btn
                  text
                  outlined
                  color='primary'
                  style='width: 325px'
                  @click='createAllergyForm'
                  :disabled='isBusy'
                  :loading='isBusy'
              >Create Patient Questionnaire Form
              </v-btn
              >
            </v-col>
            <v-col :sm='12' :xs='12'>
              <v-btn
                  text
                  outlined
                  color='primary'
                  style='width: 325px'
                  @click='createMoreDetailsForm'
                  :disabled='isBusy'
                  :loading='isBusy'
              >Create Allergy Survey Form
              </v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!--<v-btn flat :disabled='submit.working' @click='closeDlg'>Close</v-btn>-->
        <v-btn text @click='closeDlg' :disabled='isBusy'>Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../api/web';

export default {
  props: {
    dlgOptions: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      isBusy: false
    };
  },
  methods: {
    closeDlg() {
      this.$emit('close');
    },
    bookAppointment() {
      this.$emit('bookAppointment');
    },
    async createAllergyForm() {
      await this.api_createAllergyForm();
    },
    async createMoreDetailsForm() {
      await this.api_createMoreDetailsForm();
    },
    async api_createAllergyForm() {
      this.isBusy = true;
      const res = await WebH.Get1('/Allergies/' + this.item.Id + '/create', '');
      this.isBusy = false;
      if (res.Success) {
        this.$notify({
          type: 'info',
          text: 'Successfully create a allergy form for the patient'
        });
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
    },
    async api_createMoreDetailsForm() {
      this.isBusy = true;
      const res = await WebH.Get1(
          '/PatientHistories/' + this.item.Id + '/create',
          ''
      );
      this.isBusy = false;
      if (res.Success) {
        this.$notify({
          type: 'info',
          text:
              'Successfully create a more allergy details form for the patient'
        });
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
    }
  }
};
</script>

<style scoped></style>

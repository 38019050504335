<template>
  <v-dialog v-model='dlgAppointment' scrollable persistent max-width='400px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>
          Book Appointment
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-menu
          ref='dtMenu'
          :close-on-content-click='false'
          lazy
          nudge-right='40'
          offset-y
          full-width
          max-width='290px'
          min-width='290px'
          v-model='dtMenu'
        >
          <template v-slot:activator='{ on }'>
            <v-text-field
              v-model='selectedDate'
              label='Select an appointment date:'
              v-on='on'
              readonly
            >
              <template slot='append'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model='selectedDate'
            no-title
            @input='dtMenu = false'
            :min='minDate'
            :max='maxDate'
            :allowed-dates='allowDate'
          ></v-date-picker>
        </v-menu>
        <v-autocomplete
          label='Available time slots:'
          :items='items'
          item-text='Name'
          item-value='Id'
          :loading='isBusy'
          v-model='selectedSlot'
        >
        </v-autocomplete>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click='closeDlg' :disabled='isBusy'>Close</v-btn>
        <v-btn
          text
          color='primary darken-1'
          :disabled='isBusy'
          @click='bookAppointment'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../api/web';
import moment from 'moment';

export default {
  props: {
    dlgAppointment: {
      type: Boolean,
      default: false
    },
    patient: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      isBusy: false,
      dtMenu: false,
      allowedDates: [],
      minDate: null,
      maxDate: null,
      items: [],
      selectedDate: null,
      selectedSlot: -1
    };
  },
  created() {
    let allowed_dates = [];
    let dt = new Date();
    dt.setDate(dt.getDate() - 1);
    while(allowed_dates.length < 7) {
      const date =  new Date(dt.setDate(dt.getDate() + 1))
      if (date.getDay() !== 0) {
        allowed_dates.push(date);
      }
    }

    this.minDate = allowed_dates[0].toISOString();
    this.maxDate = allowed_dates[6].toISOString();
  },
  watch: {
    selectedDate: function() {
      this.api_getSlots();
    }
  },
  methods: {
    allowDate(strDate) {
      return moment(strDate).day() !== 0;
    },
    closeDlg() {
      this.items = [];
      this.selectedSlot = -1;
      this.selectedDate = null;
      this.$emit('close');
    },
    getItem(id) {
      switch (id) {
        case 1:
        case 6:
        case 11:
        case 16:
        case 21:
        case 31:
        case 36:
        case 41:
        case 26:
        case 27:
        case 28:
        case 29:
        case 30:
          return null;
        default:
          return { Id: id, Name: this.$options.filters.IntToSlot(id) };
      }
    },
    async bookAppointment() {
      if (!this.selectedDate) {
        await this.$notifyDlg.show(
          'Please select an appointment date',
          'Error',
          'error'
        );
        return;
      }
      if (this.selectedSlot < 1) {
        await this.$notifyDlg.show(
          'Please select an appointment slot',
          'Error',
          'error'
        );
        return;
      }
      await this.api_bookAppointment({
        Id: this.patient.Id,
        AppointmentSlot: this.selectedSlot,
        AppointmentDate: this.selectedDate
      });
    },
    async api_getSlots() {
      if (!this.selectedDate) return;
      this.isBusy = true;
      this.items = [];
      const res = await WebH.Get1(
        '/apptmnts/slots?ad=' + this.selectedDate,
        'appointment slots'
      );
      if (res.Success) {
        const slots = res.Data;
        for (let i = 1; i <= 50; i++) {
          const item = this.getItem(i);
          if (!item) continue;
          if (slots.includes(item.Id)) continue;
          this.items.push(item);
        }
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
      this.selectedSlot = -1;
      this.isBusy = false;
    },
    async api_bookAppointment(data) {
      this.isBusy = true;
      const res = await WebH.Post(
        '/apptmnts/' + this.patient.Id + '/book',
        data
      );
      this.isBusy = false;
      if (res.Success) {
        await this.$notifyDlg.show(
          'Successfully booked appointment on ' +
            data.AppointmentDate +
            '\r\n Soon you will get the confirmation sms to your mobile'
        );
        this.closeDlg();
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
    }
  }
};
</script>

<style scoped></style>

<template>
  <v-data-table
      class='elevation-1'
      :footer-props="footerProps"
      :headers='headers'
      :items='patients'
      item-key='Id'
      :loading='loading'
      :sort-by.sync='pageSortBy'
      sort-desc.sync='false'
  >
    <template v-slot:item.actions="{ item }">
      <td style='text-align: center'>
        <button class='linkBtn' @click='viewOptions(item)'>
          Options
        </button>
        <button class='linkBtn' type='button' @click='viewDlg(item)'>
          View
        </button>
        <button
            class='linkBtn'
            type='button'
            v-show='allowEdit'
            @click='editDlg(item)'
        >
          Edit
        </button>
        <button
            class='linkBtn'
            type='button'
            v-show='allowDelete'
            @click='deleteDlg(item)'
        >
          Delete
        </button>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ['patients', 'loading', 'allowEdit', 'allowDelete'],
  data() {
    return {
      footerProps: {itemsPerPageOptions: [25, 50]},
      pageSortBy: 'PatientId',
      headers: [
        {
          text: 'Patient Id',
          value: 'PatientId',
          sortable: true,
          width: '10%'
        },
        {text: 'Name', value: 'Name', sortable: true, width: '20%'},
        {text: 'Mobile', value: 'Mobile', sortable: true, width: '10%'},
        {
          text: 'Email',
          value: 'Email',
          sortable: true,
          width: '20%'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '20%',
          align: 'center'
        }
      ]
    };
  },
  methods: {
    viewOptions(item) {
      this.$emit('options', item);
    },
    viewDlg(item) {
      this.$emit('view', item);
    },
    editDlg(item) {
      this.$emit('edit', item);
    },
    deleteDlg(item) {
      this.$emit('delete', item);
    }
  }
};
</script>

<style scoped></style>

<template>
  <v-dialog v-model='dlgShow' scrollable persistent max-width='400px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>{{ title }} patient</div>
      </v-card-title>
      <v-card-text>
        <v-menu
          ref='dtMenu'
          :close-on-content-click='false'
          nudge-right='40'
          offset-y
          max-width='290px'
          min-width='290px'
          v-model='dtMenu'
        >
          <template v-slot:activator='{ on }'>
            <v-text-field
              v-model='dlgModel.RegisteredDate'
              label='Select a patient registration date:'
              v-on='on'
              name='rDt'
              readonly
              v-validate='{ required: true }'
              :error='!!errors.has("rDt")'
              :error-messages='errors.first("rDt")'
            >
              <template slot='append'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model='dlgModel.RegisteredDate'
            no-title
            @input='dtMenu = false'
          ></v-date-picker>
        </v-menu>
        <v-text-field
          v-model.trim='dlgModel.Name'
          name='fName'
          label='Full Name:'
          :disabled='dlgType > 1'
          v-validate='{ required: true }'
          :error='!!errors.has("fName")'
          :error-messages='errors.first("fName")'
        ></v-text-field>
        <v-text-field
          v-model='dlgModel.PatientId'
          :disabled='dlgType > 1'
          name='patientId'
          label='Patient Id:'
        ></v-text-field>
        <v-text-field
          :disabled='dlgType === 2'
          v-model.trim='dlgModel.Email'
          name='email'
          label='Email:'
        ></v-text-field>
        <v-text-field
          :disabled='dlgType === 2'
          v-model.trim='dlgModel.Mobile'
          name='mobile'
          label='Mobile Number:'
          v-validate='{ required: true, min: 10, max: 20 }'
          :error='!!errors.has("mobile")'
          :error-messages='errors.first("mobile")'
        ></v-text-field>
        <v-textarea
          :disabled='dlgType === 2'
          v-model.trim='dlgModel.Address'
          label='Address:'
          no-resize
          rows='2'
        ></v-textarea>
        <v-text-field
          label='City:'
          v-model='dlgModel.City'
          :disabled='dlgType === 2'
        >
        </v-text-field>
        <v-text-field
          label='State:'
          v-model='dlgModel.State'
          :disabled='dlgType === 2'
        >
        </v-text-field>
        <v-text-field
          label='Country:'
          v-model='dlgModel.Country'
          :disabled='dlgType === 2'
        >
        </v-text-field>
        <v-text-field
          label='PIN:'
          v-model='dlgModel.PIN'
          :disabled='dlgType === 2'
        >
        </v-text-field>
        <v-text-field
          label='Age:'
          v-model.number='dlgModel.Age'
          :disabled='dlgType === 2'
        >
        </v-text-field>
        <v-autocomplete
          :disabled='dlgType === 2'
          :items='genders'
          v-model='dlgModel.Gender'
          label='Gender:'
          name='gender'
          item-text='Text'
          item-value='Id'
          v-validate='{ required: true }'
          :error='!!errors.has("gender")'
          :error-messages='errors.first("gender")'
        >
        </v-autocomplete>
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-item>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
          :disabled='dlgType === 2'
          color='primary darken-1'
          text
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../api/web';
import Helper from '../../api/helperMethods';
export default {
  props: {
    dlgShow: {
      type: Boolean,
      default: false
    },
    dlgType: {
      type: Number,
      default: 2
    },
    item: Object
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      dtMenu: false,
      dlgModel: {
        RegisteredDate: null,
        Name: '',
        PatientId: '',
        Age: '',
        Email: '',
        Mobile: '',
        Address: '',
        City: '',
        State: 'Karnataka',
        Country: 'India',
        PIN: '',
        Gender: ''
      },
      genders: [
        { Id: 0, Text: 'Male' },
        { Id: 1, Text: 'Female' },
        { Id: 2, Text: 'Others' },
        { Id: 3, Text: 'Unknown' }
      ]
    };
  },
  computed: {
    title() {
      return this.dlgType === 0
        ? 'Create'
        : this.dlgType === 1
        ? 'Edit'
        : 'View';
    }
  },
  watch: {
    item: function() {
      if (this.dlgType === 0) {
        this.dlgModel = {
          RegisteredDate: null,
          Name: '',
          PatientId: '',
          Age: '',
          Email: '',
          Mobile: '',
          Address: '',
          City: '',
          State: 'Karnataka',
          Country: 'India',
          PIN: '',
          Gender: ''
        };
      } else {
        this.dlgModel = {
          RegisteredDate: this.$options.filters.FormatDateISO(
            this.item.RegisteredDate
          ),
          Name: this.item.Name,
          PatientId: this.item.PatientId,
          Age: Helper.CalculateAge(this.item.DOB),
          Email: this.item.Email,
          Mobile: this.item.Mobile,
          Address: this.item.Address,
          City: this.item.City,
          State: this.item.State,
          Country: this.item.Country,
          PIN: this.item.PIN,
          Gender: this.item.Gender
        };
      }
    }
  },
  methods: {
    closeDlg() {
      this.errors.clear();
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dlgModel = {
        RegisteredDate: null,
        Name: '',
        PatientId: '',
        Age: '',
        Email: '',
        Mobile: '',
        Address: '',
        City: '',
        State: 'Karnataka',
        Country: 'India',
        PIN: '',
        Gender: ''
      };
      this.$emit('close');
    },
    async saveDlg() {
      const res = await this.$validator.validateAll();
      if (!res) return;
      if (this.dlgType === 0) {
        this.api_create(this.dlgModel);
      } else {
        const newItem = {
          RegisteredDate: this.item.RegisteredDate,
          Id: this.item.Id,
          Name: this.dlgModel.Name,
          Age: this.dlgModel.Age,
          PatientId: this.dlgModel.PatientId,
          Email: this.dlgModel.Email,
          Mobile: this.dlgModel.Mobile,
          Address: this.dlgModel.Address,
          City: this.dlgModel.City,
          State: this.dlgModel.State,
          Country: this.dlgModel.Country,
          PIN: this.dlgModel.PIN,
          Gender: this.dlgModel.Gender
        };
        this.api_update(newItem);
      }
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/patients', data);
      this.submit.working = false;
      if (res.Success) {
        this.$emit('add', res.Data);
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    },
    async api_update(data) {
      this.submit.working = true;
      const res = await WebH.Put('/patients/' + data.Id, data);
      this.submit.working = false;
      if (res.Success) {
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>

<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Patient List
        </div>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-btn color='primary' v-show='allowAdd' @click='createDlg'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          New Patient
        </v-btn>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-layout row wrap>
          <div class='filterStyle'>
            <v-text-field label='Patient Id:' v-model='searchModel.pid'>
              <template slot='prepend'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-filter</v-icon
                >
              </template>
            </v-text-field>
          </div>
          <div class='filterStyle'>
            <v-text-field label='Name:' v-model='searchModel.name'>
              <template slot='prepend'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-filter</v-icon
                >
              </template>
            </v-text-field>
          </div>
          <div class='filterStyle'>
            <v-text-field label='Mobile:' v-model='searchModel.mob'>
              <template slot='prepend'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-filter</v-icon
                >
              </template>
            </v-text-field>
          </div>
          <v-flex>
            <v-btn :disabled='loading' @click='api_get'>
              <v-icon class='iconText'>fa fa-search</v-icon>
              Search
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout row wrap> </v-layout>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-card>
          <v-card-text class='pa-0'>
            <PatientsDisplay
              :patients='patients'
              :loading='loading'
              :allow-edit='allowEdit'
              :allow-delete='allowDelete'
              @view='viewDlg'
              @edit='editDlg'
              @delete='deleteDlg'
              @options='optionsDlg'
            ></PatientsDisplay>
            <PatientDlg
              :dlg-show='dlgShow'
              :dlg-type='dlgType'
              :item='selectedItem'
              @close='closeDlg'
              @add='addNewPatient'
            >
            </PatientDlg>
            <OptionsDlg
              :dlg-options='dlgOptions'
              :item='selectedItem'
              @close='closeDlg'
              @bookAppointment='bookAppointmentDlg'
            ></OptionsDlg>
            <BookAppointmentDlg
              :dlg-appointment='dlgAppointment'
              :patient='selectedItem'
              @close='closeDlg'
            />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import WebH from '../../api/web';
import PatientsDisplay from '../../components/patients/PatientsDisplay';
import PatientDlg from '../../components/patients/PatientDlg';
import OptionsDlg from '../../components/appointment/PatientOptionsDlg';
import BookAppointmentDlg from '../../components/appointment/BookAppointmentDlg';
export default {
  components: {
    PatientsDisplay,
    PatientDlg,
    OptionsDlg,
    BookAppointmentDlg
  },
  created() {
    this.allowAdd = this.$store.getters['UserStore/permissions'].includes(
      'pat.a'
    );
    this.allowEdit = this.$store.getters['UserStore/permissions'].includes(
      'pat.e'
    );
    this.allowDelete = this.$store.getters['UserStore/permissions'].includes(
      'pat.r'
    );
  },
  data() {
    return {
      allowEdit: false,
      allowDelete: false,
      allowAdd: false,
      loading: false,
      patients: [],
      searchModel: {
        name: '',
        pid: '',
        mob: ''
      },
      dlgShow: false,
      dlgOptions: false,
      dlgAppointment: false,
      dlgType: 0,
      selectedItem: null
    };
  },
  methods: {
    closeDlg(deleteSelectedItem = true) {
      if (deleteSelectedItem) {
        this.selectedItem = null;
      }

      this.dlgShow = false;
      this.dlgOptions = false;
      this.dlgAppointment = false;
    },
    viewDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 2;
      this.dlgShow = true;
    },
    editDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 1;
      this.dlgShow = true;
    },
    optionsDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgOptions = true;
    },
    createDlg() {
      this.selectedItem = null;
      this.dlgType = 0;
      this.dlgShow = true;
    },
    bookAppointmentDlg() {
      this.closeDlg(false);
      this.dlgAppointment = true;
    },
    addNewPatient(patient) {
      this.patients.push(patient);
      this.$notify({ type: 'info', text: 'Successfully added new patient' });
    },
    async deleteDlg(item) {
      const res = await this.$notifyDlg.show(
        'Do you really want delete this patient?',
        'Delete patient',
        'warning',
        true
      );
      if (res) {
        this.api_delete(item);
      }
    },
    async api_get() {
      this.loading = true;
      this.patients = [];
      let url = '/patients/search?';
      url += 'name=' + this.searchModel.name;
      url += '&pid=' + this.searchModel.pid;
      url += '&mob=' + this.searchModel.mob;
      const res = await WebH.Get(url, 'patients');
      if (res.Success) {
        this.patients = res.Data;
      }
      this.loading = false;
    },
    async api_delete(data) {
      const res = await WebH.Delete('/patients/' + data.Id);
      if (res.Success) {
        const index = this.patients.findIndex(data);
        if (index) {
          this.patients.splice(index, 1);
        }
        this.$notify({
          type: 'info',
          text: 'Successfully deleted the patient'
        });
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'patients/cp') {
      next(vm => {
        vm.patients = vm.$store.getters['PatientsStore/patients'];
      });
    } else
      next(vm => {
        vm.api_get();
      });
  }
};
</script>

<style scoped></style>
